<template>
  <div>
    <div>
      <div class="flex flex-wrap bg-primary-50 text-center mb-8 px-4 py-4">
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ total_alarm }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_alarm_center.number_of_alarm") }}-a</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ total_unit }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_alarm_center.number_of_business") }}-b</div>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 px-2 py-2">
          <div class="text-primary-500 font-bold text-2xl mb-2">{{ push_button_count }}</div>
          <div class="font-bold mb-1 font-serif">{{ $t("dashboard_alarm_center.number_of_push_b") }}-c</div>
        </div>
      </div>

      <div class="flex flex-wrap text-center justify-center lg:justify-start mt-8">
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-4 mt-2">
          <router-link tag="div" :to="'/alarm-center/securtrack/' + alarm_center_id" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("common.securTrack") }}
            <BaseIcon class="text-white ml-2" icon="map-marker-alt" />
          </router-link>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-4 mt-2">
          <router-link tag="div" :to="'/alarm-center/securtool/' + alarm_center_id" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("common.securTool") }}
            <BaseIcon class="text-white ml-2" icon="history" />
          </router-link>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 md:pr-4 mt-2">
          <router-link tag="div" :to="'/alarm-center/alarm-via-securcloud/' + alarm_center_id" class="text-white font-serif bg-primary-300 py-12 font-bold shadow hover:shadow-lg hover:bg-primary-400 rounded cursor-pointer transition duration-200">
            {{ $t("dashboard_alarm_center.alarm_via_sc") }}
            <BaseIcon class="text-white ml-2" icon="angle-double-right" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DashboardAlarmCenter",
  title() {
    return this.$t("page_titles.alarm_center");
  },
  data() {
    return {
      alarm_center_id: this.$route.params.id,
      alarm_center: null,
      total_alarm: "-",
      total_unit: "-",
      push_button_count: "-",
    };
  },
  methods: {
    async getAlarmCenterDashboard() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dashboard/alarm-center/${this.alarm_center_id}`);
        this.alarm_center = response.data.alarm_center;
        this.total_alarm = response.data.total_alarm;
        this.total_unit = response.data.total_unit;
        this.push_button_count = response.data.push_button_count;

        this.setPageTitle(`${this.alarm_center.name} - Dashboard`, "alarm_center_dashboard");
      } catch (error) {
        this.handleError(error);
      }
    },
  },

  created() {
    this.getAlarmCenterDashboard();
  },

  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.alarm_center");
    },
  },
};
</script>
